@import url(https://fonts.googleapis.com/css?family=Lato:400,300,400italic,900);
@import url(https://fonts.googleapis.com/css?family=Lora:400,400italic,700,700italic);
body {
  background: #fff;
  color: #00326B;
  font-family: "Lora", serif;
  font-size: 18px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.asicblock {
  display: block;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.asicregular {
  padding: 64px 16px;
  max-width: 1032px;
  background: #fafafa;
  margin: 100px auto;
}

@media all and (max-width: 1032px) {
  .asicregular {
    padding: 32px 16px;
  }
}

.asichero {
  padding-bottom: 64px;
  position: relative;
  margin-top: -21px;
}

.asichero .asichero-background {
  background-size: cover;
  background-repeat: no-repeat;
  height: 1000px;
  position: relative;
}

.asichero .asichero-background .overlay {
  height: 1000px;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.55);
  z-index: 1;
}

.asichero header,
.asichero article {
  padding: 64px 16px;
  max-width: 1032px;
  margin: 0 auto;
  z-index: 2;
}

.asichero header {
  color: #fff;
  position: relative;
  text-align: center;
}

.asichero header h1 {
  margin-top: 200px;
}

.asichero header h2 {
  font-family: "Lora", serif;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
}

.asichero .asicmeta {
  position: absolute;
  width: 1000px;
  left: 50%;
  margin: 0 0 0 -350px;
  bottom: 32px;
  z-index: 5;
  text-align: center;
}

.asichero .asicmeta .asicavatar, .asichero .asicmeta .asicname, .asichero .asicmeta .length, .asichero .asicmeta .asicdate {
  display: block;
  text-align: center;
}

.asichero .asicmeta .asicname {
  color: #fff;
  margin: 5px 0;
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 18px;
  text-transform: normal;
  -webkit-font-smoothing: antialiased;
}

.asichero .asicmeta img {
  display: inline-block;
}

.asichero .asicmeta .asicdate {
  color: #fff;
  margin-top: 10px;
  -webkit-font-smoothing: antialiased;
}

section header .meta .author {
  float: left;
}

section header .meta img {
  float: left;
  margin-right: 12px;
}

section header .meta a {
  display: block;
}

section header .meta .asicdate {
  margin-top: 8px;
  float: right;
}

footer.asicarticle-footer {
  background: #fafafa;
  padding: 32px 0;
}

.block .meta {
  margin: 5px 0 0;
}

footer.asicpage {
  background: #fff;
  padding: 64px 0;
  text-align: center;
}

footer.asicpage p.asiccopyright {
  font-family: "Lato", sans-serif;
  font-weight: 1000;
  font-size: 14px;
}

footer.asicpage p.asictribute {
  color: #0056A2;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .5em;
}

.section-things {
  background: #f2f2f2;
}

.section-things section header {
  padding: 64px 0 32px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  z-index: 2;
}

.asicread-more {
  margin-top: 96px;
  font-family: "Lato", sans-serif;
  text-align: center;
  display: block;
  font-family: "Lato", sans-serif;
  color: #0056A2;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .5em;
}

.asicread-more a {
  color: #0056A2;
}

article.asiccontainer {
  margin-left: 40px;
  padding: 5px;
  font-family: "Lora", serif;
  background: #fafafa;
  text-align: left;
  color: #00326B;
}

article.asiccontainer .asiccontent {
  margin-left: 40px;
  padding: 5px;
  font-family: "Lora", serif;
  background: #fafafa;
  text-align: left;
  color: #00326B;
}

article.asiccontainer content, article.asiccontainer li, article.asiccontainer ol, article.asiccontainer ul {
  margin-left: 200px;
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: -.025em;
  margin: 0 0 32px -1px;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 12px;
  letter-spacing: .15em;
  text-transform: uppercase;
}

h6 em {
  color: #0056A2;
  font-style: normal;
}

@media all and (max-width: 732px) {
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 18px;
  }
}

.asicspecial {
  font-family: "Lora", serif;
  margin-bottom: 64px;
}

.asicspecial small {
  display: block;
  font-family: "Lato", sans-serif;
  color: #0056A2;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .5em;
  margin-bottom: 10px;
}

section header h1 {
  line-height: 1;
  margin-bottom: 32px;
}

section header h2 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 64px;
}

@media all and (max-width: 772px) {
  section header h2 {
    font-size: 28px;
  }
}

article.asiccontent p {
  font-family: "Lora", serif;
  font-size: 18px;
  line-height: 32px;
  margin: 0 0 0 40px 32px;
}

article.asiccontent p > a {
  color: #0056A2;
  text-decoration: none;
  text-shadow: -1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;
  position: relative;
  white-space: nowrap;
}

article.asiccontent p > a:after {
  display: block;
  position: absolute;
  width: 100%;
  border-top: 1px solid #000;
  content: "";
  bottom: 0.1em;
  left: 0;
  z-index: -1;
}

article.asiccontent blockquote {
  border: none;
  padding: 0;
  margin: 0;
  margin: 0 0 96px;
  text-align: center;
  padding-top: 64px;
  position: relative;
}

article.asiccontent blockquote p {
  font-size: 28px;
  font-style: italic;
  line-height: 1.5;
  margin: 0;
}

article.asiccontent blockquote:before {
  content: '';
  width: 80px;
  height: 6px;
  background: #ddd;
  top: 32px;
  left: 50%;
  position: absolute;
  margin-left: -40px;
}

article.asiccontent ul, article.asiccontent ol {
  margin-bottom: 32px;
}

article.asiccontent li {
  margin-left: 40px;
}

article.asiccontent li {
  margin-bottom: 16px;
}

article.asiccontent li:last-child {
  margin-bottom: 0;
}

article.asiccontent img {
  margin-top: 10px;
  max-width: 100%;
  text-align: center;
}

article.asiccontent img.yank-left {
  max-width: 30%;
  float: left;
  margin-left: -15%;
  margin-right: 32px;
}

article.asiccontent img.yank-right {
  max-width: 30%;
  float: right;
  margin-right: -15%;
  margin-left: 32px;
}

article.asiccontent img.oversized {
  margin: 0 -15% 0 -15%;
  max-width: 130%;
}

article.asiccontent p.caption {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  padding-top: 3px;
}

article.asiccontent iframe {
  margin-bottom: 32px;
}

article.asiccontent a {
  color: #0056A2;
}

.block {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.block h3 {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin: 0 0 5px;
  line-height: 1.5;
}

.block p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  margin: 0;
}

.block a {
  color: #00326B;
}

.block a:hover, .block a:focus {
  text-decoration: none;
}

.block img {
  max-width: 100%;
}

.block img.small-image {
  border: 1px solid rgba(0, 0, 0, 0.05);
  float: right;
  margin: 3px 0 0 32px;
}

.block img.large-image {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
}

.meta {
  color: #0056A2;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  margin: 0 0 64px;
}

.meta a {
  color: #005566;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
}

.meta .date {
  color: #00326B;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
}

code {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 16px;
}

.asiccentered {
  text-align: center;
}

.asicgallery header {
  padding: 64px 0 32px;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  z-index: 2;
}

.asicgallery-images {
  padding: 100px;
}

.asicgallery-images img {
  vertical-align: bottom;
  opacity: 0;
}

.asicvideo {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
  display: inline-flex !important;
  overflow: hidden;
}

.asicvideo header {
  text-align: left;
}

.asicvideo iframe {
  position: absolute;
  margin-left: 15px;
  display: inline-flex !important;
  opacity: 0;
}

iframe:empty {
  display: none;
}

.dropdown ul, li {
  list-style-type: none;
}

.dropdown-submenu {
  position: relative;
  float: left;
}

.dropdown-submenu ul {
  padding: 0;
  list-style-type: none;
}

.dropdown-submenu > a:after {
  content: "\f0da";
  float: right;
  border: none;
  font-family: 'FontAwesome';
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.dropdown-submenu > .dropdown-menu ul {
  padding: 0;
  list-style-type: none;
}

/* The sidebar menu */
.asicsidenav {
  height: 100%;
  /* Full-height: remove this if you want "auto" height */
  width: 200px;
  /* Set the width of the sidebar */
  z-index: 1;
  /* Stay on top */
  top: 10;
  /* Stay at the top */
  left: 600;
  background-color: #FFFFFF;
  /* Black */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 10px;
  color: #000000;
  text-decoration: underline;
}

/* The navigation menu links */
.asicsidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none !important;
  font-size: 15px;
  color: #011DC9 !important;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.asicsidenav a:hover {
  color: #47ADC6 !important;
}

/* Standard text style for non-links */
.asicsidenavplain {
  font-size: 15px;
  color: #3036C4;
  text-decoration: underline;
}

/* Style page content */
.asicmain {
  margin-left: 160px;
  /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .asicsidenav {
    padding-top: 10px;
  }
  .asicsidenav a {
    font-size: 12px;
  }
}

.asictable {
  width: 100%;
  align-items: center;
  min-width: 100px;
  margin-top: 0px;
}

.asictable td, .asictable th {
  text-align: left;
  min-width: 100px;
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: .5rem;
  padding-right: .5rem;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.asictable td td:before, .asictable th td:before {
  content: '';
  display: block;
  width: 5em;
}

.textarea {
  rows: 4;
  height: 100px;
  width: 100%;
  align-items: center;
  min-width: 100px;
  margin-top: 0px;
}
