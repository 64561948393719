.asicgallery {
	header {
		padding: ($gap * 2) 0 $gap;
		text-align: center;
		max-width: 700px;
		margin: 0 auto;
		z-index: 2;
	}
}

.asicgallery-images {
	padding: 100px;
}

.asicgallery-images img {
	vertical-align: bottom;
	opacity: 0;
}
