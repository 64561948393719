.dropdown ul, li{
      list-style-type: none;
  }

.dropdown-submenu {
  position: relative;
  float:left;

  ul {
      padding: 0;
      list-style-type: none;
  }
}

.dropdown-submenu>a:after {
  content: "\f0da";
  float: right;
  border: none;
  font-family: 'FontAwesome';
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;

  ul {
      padding: 0;
      list-style-type: none;
  }
}
