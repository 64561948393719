.asicregular {
	padding: ($gap * 2) $gap / 2;
	max-width: 1000px + $gap;
	background: #fafafa;
	margin: 100px auto;
}

@media all and (max-width: 1000px + $gap) {
    .asicregular { padding: $gap $gap/2; }
}

.asichero {
	padding-bottom: ($gap * 2);
	position: relative;
	margin-top: -21px;

	.asichero-background {
		background-size: cover;
		background-repeat: no-repeat;
		height: 1000px;
		position: relative;

		.overlay {
			height: 1000px;
			width: 100%;
			position: absolute;
			background: rgba(0,0,0,.55);
			z-index: 1;
		}
  }

	header,
	article {
		padding: ($gap * 2) $gap / 2;
		max-width: 1000px + $gap;
		margin: 0 auto;
		z-index: 2;
	}

	header {
		color: #fff;
		position: relative;
		text-align: center;
		h1 {
			margin-top: 200px;
		}
		h2 {
			font-family: $font_serif;
			font-style: italic;
			-webkit-font-smoothing: antialiased;
		}
	}

	.asicmeta {
		position: absolute;
		width: 1000px;
		left: 50%;
		margin: 0 0 0 -350px;
		bottom: $gap;
		z-index: 5;
		text-align: center;

		.asicavatar, .asicname, .length, .asicdate {
			display: block;
			text-align: center;
		}

		.asicname {
			color: #fff;
			margin: 5px 0;
			font-family: $font_serif;
			font-style: italic;
			font-size: 18px;
			text-transform: normal;
			-webkit-font-smoothing: antialiased;
		}

		img {
			display: inline-block;
		}

		.asicdate {
			color: #fff;
			margin-top: 10px;
			-webkit-font-smoothing: antialiased;
		}
	}
}

section header {
	.meta .author {
		float: left;
	}

	.meta img {
		float: left;
		margin-right: 12px;
	}

	.meta a {
		display: block;
	}

	.meta .asicdate {
		margin-top: 8px;
		float: right;
	}
}

footer.asicarticle-footer {
	background: #fafafa;
	padding: $gap 0;
}

.block .meta {
	margin: 5px 0 0;
}

footer.asicpage {
    background: #fff;
	padding: ($gap * 2) 0;
	text-align: center;
	p.asiccopyright {
		font-family: $font_sans;
		font-weight: 1000;
		font-size: 14px;
	}
	p.asictribute {
		color: $color_light;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: .5em;
	}
}

.section-things {
    background: #f2f2f2;

    section header {
		padding: ($gap * 2) 0 $gap;
		text-align: center;
		max-width: 1000px;
		margin: 0 auto;
		z-index: 2;
	}
}
.asicread-more {
    margin-top: $gap * 3;
    font-family: $font_sans;
    text-align: center;
    display: block;
    font-family: $font_sans;
    color: $color_light;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: .5em;

    a { color: $color_light; }
}

article.asiccontainer {
	margin-left: 40px;
	padding: 5px;
	font-family: $font_serif;
	background: #fafafa;
	text-align: left;
	color: $color_dark;

  .asiccontent{
  	margin-left: 40px;
  	padding: 5px;
  	font-family: $font_serif;
  	background: #fafafa;
  	text-align: left;
  	color: $color_dark;
  }

	content, li, ol, ul{
		margin-left: 200px;
		text-align: left;
	}
}
