/* The sidebar menu */
.asicsidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 200px; /* Set the width of the sidebar */

  z-index: 1; /* Stay on top */
  top: 10; /* Stay at the top */
  left: 600;
  background-color: #FFFFFF; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px;
  color: #000000;
  text-decoration: underline;
}

/* The navigation menu links */
.asicsidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none !important;
  font-size: 15px;
  color: #011DC9 !important;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.asicsidenav a:hover {
  color: #47ADC6 !important;
}

/* Standard text style for non-links */
.asicsidenavplain {
  font-size: 15px;
  color: #3036C4;
  text-decoration: underline;
}

/* Style page content */
.asicmain {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .asicsidenav {padding-top: 10px;}
  .asicsidenav a {font-size: 12px;}
}
