.asictable {
	width: 100%;
	align-items: center;
	min-width: 100px;
	margin-top: 0px;


  td, th {
  	text-align: left;
		min-width: 100px;
  	padding-top: 0rem;
  	padding-bottom: 0rem;
  	padding-left: .5rem;
  	padding-right: .5rem;
  	border-bottom: 1px solid black;
  	border-top: 1px solid black;

		td:before {
      content: '';
      display: block;
      width: 5em;
    }
	}
}

.textarea {
	rows: 4;
	height: 100px;
	width: 100%;
	align-items: center;
	min-width: 100px;
	margin-top: 0px;

}
