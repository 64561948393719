//Base Colors
$color_dark:  #00326B;
$color_light: #0056A2;

$color_teal: #005566;
$color_gold: #FFCC4C;

// Fontsicles
$font_sans:  'Lato', sans-serif;
$font_serif: 'Lora', serif;

$gap: 32px;
