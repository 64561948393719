.asicvideo {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
  display:inline-flex !important;
  overflow: hidden;
}

.asicvideo header {
	  text-align: left;
}

.asicvideo iframe {
	  position: absolute;
    margin-left: 15px;
    display: inline-flex !important;
    opacity: 0;
}

iframe:empty {
  display: none;
}
